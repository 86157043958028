//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

// 支付：
export function payObj(obj) {
    return request({
        url: '/payment/pays/getQRCode',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}


