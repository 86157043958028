<template>
    <div class="case-conent recharge">
        <div class="hd">充值购买</div>
        <!--搜索结果-->
        <div class="tab-tilte">
            <li @click="curA=0" :class="{active:curA==0}">按需充值</li>
            <li @click="toCart()">购物车</li>
        </div>
        <div class="tab-content">
            <div class="rech" v-show="curA==0">
                <ul class="msg-box">
                    <li>
                        <div class="listA">
                            <el-radio-group v-model="amountVal" @change="amountChange">
                                <div class="recList">
                                    <div class="lst">
                                        <div class="d1">
                                            <img src="../../assets/images/rech-ioc-01.png" alt="">
                                        </div>
                                        <div class="d2">100</div>
                                    </div>
                                    <div class="lb">
                                        <el-radio border :label="''+ 100" style="border: none">充值100</el-radio>
                                    </div>
                                </div>
                                <div class="recList">
                                    <div class="lst">
                                        <div class="d1">
                                            <img src="../../assets/images/rech-ioc-02.png" alt="">
                                        </div>
                                        <div class="d2">500</div>
                                    </div>
                                    <div class="lb">
                                        <el-radio border :label="''+ 500" style="border: none">充值500</el-radio>
                                    </div>
                                </div>
                                <div class="recList">
                                    <div class="lst">
                                        <div class="d1">
                                            <img src="../../assets/images/rech-ioc-03.png" alt="">
                                        </div>
                                        <div class="d2">1000</div>
                                    </div>
                                    <div class="lb">
                                        <el-radio border :label="''+ 1000" style="border: none">充值1000</el-radio>
                                    </div>
                                </div>
                                <div class="recList">
                                    <div class="lst">
                                        <div class="d1">
                                            <img src="../../assets/images/rech-ioc-04.png" alt="">
                                        </div>
                                        <div class="d2">2000</div>
                                    </div>
                                    <div class="lb">
                                        <el-radio border :label="''+ 2000" style="border: none">充值2000</el-radio>
                                    </div>
                                </div>
                                <div class="recList">
                                    <div class="lst">
                                        <div class="d1">
                                            <img src="../../assets/images/rech-ioc-05.png" alt="">
                                        </div>
                                        <div class="d2">5000</div>
                                    </div>
                                    <div class="lb">
                                        <el-radio border :label="''+ 5000" style="border: none">充值5000</el-radio>
                                    </div>
                                </div>
                                <div class="recList">
                                    <div class="lst">
                                        <div class="d1">
                                            <img src="../../assets/images/rech-ioc-06.png" alt="">
                                        </div>
                                        <div class="d2">8000</div>
                                    </div>
                                    <div class="lb">
                                        <el-radio border :label="''+ 8000" style="border: none">充值8000</el-radio>
                                    </div>
                                </div>
                                <div class="recList">
                                    <div class="lst">
                                        <div class="d1">
                                            <img src="../../assets/images/rech-ioc-06.png" alt="">
                                        </div>
                                        <div class="d2">10000</div>
                                    </div>
                                    <div class="lb">
                                        <el-radio border :label="''+ 10000" style="border: none">充值10000</el-radio>
                                    </div>
                                </div>
                                <div class="recList">
                                    <div class="lst">
                                        <div class="d1">
                                            <img src="../../assets/images/rech-ioc-08.png" alt="">
                                        </div>
                                        <div class="d2"></div>
                                    </div>
                                    <div class="lb">
                                        <el-radio border :label="''" style="border: none">自定义</el-radio>
                                    </div>
                                </div>

                            </el-radio-group>

                        </div>
                    </li>
                    <li class="listB">
                        <span>充值金额：</span>
                        <el-input :disabled="disabled" clearable v-model="rechargeParams.totalAmt" placeholder="请输入金额"
                                  style="width: 150px;"></el-input>
                    </li>
                    <li class="listC">
                        <div class="listC-z01">充值方式：</div>
                        <div class="listC-z02">
                            <div class="tab-listC">
                                <div class="ar1">
                                    <el-radio-group v-model="rechargeParams.paymentType" @change="paymentTypeChange">
                                        <el-radio border :label="''+ 0">微信</el-radio>
                                        <el-radio border :label="''+ 1">支付宝</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="ar2">请在10分钟内购买，否则订单将会取消</div>
                            </div>
                        </div>
                        <div id="qrcode" ref="qrcode"></div>
                    </li>
                    <div class="PayBtn">
                        <el-button class="Btn" type="primary" @click="surePay">确认支付</el-button>
                    </div>
                </ul>

            </div>
        </div>
        <el-dialog
                title="充值提示"
                :visible.sync="Paydialog"
                :modal-append-to-body='false'
                width="600px"
                @close="closeDialog2"
        >
            <div class="PayB">
                <h2><i class="el-icon-success"></i>充值成功！</h2>
                <p>充值：<span>{{totalAmt01}}</span> 学币</p>
                <p>花费：<span>{{totalAmt01}}</span> 元</p>
            </div>
            <template #footer>
                <div class="dialog-footer" style="padding-bottom: 20px;box-sizing: border-box; text-align: center">
                    <el-button @click="GBPay()" round
                               style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff"
                               type="primary">确定
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <!--微信充值弹窗 -->
        <el-dialog
                title="学历案充值-微信扫码"
                :visible.sync="dialogPayment"
                :modal-append-to-body='false'
                @close="closeDialog1"
                width="400px"
        >
            <div class="payImg-code">
                <div class="totalAmt"><span>充值金额：</span>{{rechargeParams.totalAmt}} 元</div>
                <img :src="payImg" alt="">
            </div>
        </el-dialog>
        <el-dialog
                title="学历案充值-支付宝扫码"
                :visible.sync="dialogPaymentZfb"
                :modal-append-to-body='false'
                @close="closeDialog2"
                width="400px"
        >
            <div class="payImg-code">
                <div class="totalAmt"><span>充值金额：</span>{{rechargeParams.totalAmt}} 元</div>
                <iframe
                        :srcdoc="content"
                        frameborder="no"
                        border="0"
                        width="300"
                        scrolling="no"
                        id="iframeB"
                        style="overflow: hidden;">
                </iframe>
            </div>
        </el-dialog>


    </div>

</template>


<script>
    import QRCode from 'qrcode' // 引入qrcode
    import {getOrderObj} from '@/api/recharge'   //路径
    import {getStuByUserNameObj} from '@/api/caseHome'   //路径

    import {payObj} from '@/api/pay'


    export default {
        name: '',
        data() {
            return {
                totalAmt01: '',
                QRCode: '',
                qrcontent: '',
                content: '',
                dialogAlipay: false,
                Paydialog: false,//支付确认
                dialogPayment: false,
                dialogPaymentZfb: false,
                payment: '',
                curA: false,
                amountVal: '',
                disabled: false,
                payImg: '',//微信支付的二維碼
                orderNo: '',//微信支付的订单号
                //充值参数
                rechargeParams: {
                    totalAmt: '', //金额
                    paymentType: "0", //支付方式[0:微信,1:支付宝,2:余额,3:活动]
                    transType: "0" //交易类型[0:充值,1:消费]
                },
                T: '',//定时器ID
            }
        },
        created() {
            this.EducationCase = this.$route.query.EducationCase;
            this.clickFlag = this.$route.query.clickFlag;
            this.stu = this.$route.query.stu;
        },

        methods: {
            // 查询个人信息
            getStuByUser() {
                getStuByUserNameObj().then((res) => { // eslint-disable-line no-unused-vars
                    if (res.code == 200) {
                        //this.StuByUser = res.data
                        this.$store.commit('saveStuByUser', res.data)
                    }
                })

            },
            //充值金额
            amountChange(val) {
                this.rechargeParams.totalAmt = val;
                if (val == '') {
                    this.disabled = false
                } else {
                    this.disabled = true
                }
            },
            //支付方式
            paymentTypeChange(val) {
                this.rechargeParams.paymentType = val
            },
            // 跳转到购物车
            toCart() {
                this.$router.push({
                    path: "/mainb/Cart",
                    query: {
                        EducationCase: this.EducationCase,
                        stu: 1,
                        acurA: 1
                    }
                });
            },
            qrcode2() {

                // 下边css自定义就行了。。。我这主要因为别的原因才改动的
            },

            //确认支付
            surePay() {
                sessionStorage.setItem('totalAmtChange', this.rechargeParams.totalAmt)
                //支付方式跳转
                this.totalAmt01 = sessionStorage.getItem('totalAmtChange')
                if (this.rechargeParams.paymentType == 1) {
                    if (this.rechargeParams.totalAmt != 0) {
                        // this.dialogPaymentZfb=true
                        // this.loopOrderState()
                        let params = {
                            bookId: null,
                            folderId: null,
                            resourceId: null,
                            payment: Number(this.rechargeParams.totalAmt),//支付金额
                            payType: 1,//1支付宝 2 微信
                        }
                        payObj(params).then((res) => { // eslint-disable-line no-unused-vars
                            if (res.code == 200) {
                                this.content = res.data.uri;
                                this.orderNo = res.data.orderNo
                                this.loopOrderState()
                                this.dialogPaymentZfb = true
                            }
                        })


                        // this.$router.push({
                        //   path: "/mainb/alipay",
                        //   query: {
                        //     EducationCase: this.EducationCase,
                        //     clickFlag: this.clickFlag,
                        //     payment:this.rechargeParams.totalAmt,
                        //     orderCur:this.orderCur,
                        //     stu: this.stu,
                        //   }
                        // });

                    } else {
                        this.$confirm("请您选择充值金额或自定义金额, 是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning"
                        })
                    }
                } else {
                    if (this.rechargeParams.totalAmt != 0) {
                        let params = {
                            bookId: null,
                            folderId: null,
                            resourceId: null,
                            payment: Number(this.rechargeParams.totalAmt),//支付金额
                            payType: 2,//1支付宝 2 微信
                        }
                        payObj(params).then((res) => { // eslint-disable-line no-unused-vars
                            if (res.code == 200) {
                                this.qrcode = res.data
                                this.orderNo = res.data.orderNo
                                QRCode.toDataURL(res.data.uri)
                                    .then(url => {
                                        this.showPay = true;
                                        this.payImg = url;
                                        this.loopOrderState()
                                    })
                                    .catch(() => {
                                        this.$message.error('微信二维码生成失败，请稍后重试');
                                    })
                                this.dialogPayment = true
                            }


                        })
                    } else {
                        this.$confirm("请您选择充值金额或自定义金额, 是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning"
                        })
                    }
                }
            },

            // 关闭微信充值提示弹框
            GBPay() {
                sessionStorage.removeItem("totalAmtChange");
                this.rechargeParams.totalAmt = ''
                this.Paydialog = false
            },
            // 关闭微信二维码弹框
            closeDialog1() {
                clearInterval(this.T);// 清除掉，否则一直刷
                this.rechargeParams.totalAmt = ''
                //this.Paydialog=true //掉出支付确认弹框
                //this.$refs.editFormRef.resetFields()
            },
            closeDialog2() {
                sessionStorage.removeItem("totalAmtChange");
                this.rechargeParams.totalAmt = ''
                clearInterval(this.T);// 清除掉，否则一直刷
                //this.$refs.editFormRef.resetFields()
            },


            // 轮询当前订单状态
            loopOrderState() {
                this.T = setInterval(() => {
                    let obj = {
                        orderNo: this.orderNo
                    }
                    getOrderObj(obj).then((res) => { // eslint-disable-line no-unused-vars
                        if (res.data.status == 1) {// 20s 付款成功 status:1成功
                            this.Paydialog = true
                            this.getStuByUser()//刷新个人信息充值金额
                            clearInterval(this.T);// 清除掉，否则一直刷
                            this.dialogPayment = false //掉出支付确认弹框
                            this.dialogPaymentZfb = false //关闭支付确认弹框
                            //this.rechargeParams.totalAmt=''

                        }
                    })
                }, 1000)
            }
        },
        components: {},

    }
</script>
<style lang="scss" scoped>
    #iframeB {
        /*position: absolute;*/
        transform: scale(1, 1) translate(20%, 20%);

        /*transform:scaleX(1.5);*/
        /*-webkit-transform:scaleX(1.5);  !*兼容-webkit-引擎浏览器*!*/
        /*-moz-transform:scaleX(1.5)*/
        width: 200px;
        height: 180px;
        box-sizing: border-box;
        margin: auto;

    }

    .tab-tilte {
        width: 96%;
        height: 45px;
        border-bottom: 1px solid #FF9100;
        margin: 10px auto;

        li {
            float: left;
            width: 25%;
            padding: 10px 0;
            text-align: center;
            margin-right: 10px;
            font-weight: bold;
            background-color: #FFF5E8;
            border: 1px solid #FF9100;
            cursor: pointer;
            border-radius: 6px 6px 0px 0px;
        }

        .active {
            background-color: #FF9100;
            color: #fff;
        }
    }

    .payImg-code {
        width: 96%;
        text-align: center;

        .totalAmt {
            width: 100%;
            font-weight: bold;

        }

        img {
            width: 240px;
            height: 240px;
            margin: auto;
            justify-content: center;
        }

        .df0 {
            ::v-deep .el-dialog {
                width: 600px;
                height: 500px;
            }

            /*::v-deep .el-dialog--center {*/
            /*  height: 350px ;*/
            /*}*/
        }

        .dt {
            width: 500px;
            text-align: center;
            margin: auto;
            padding-left: 20%;
            overflow: hidden;
            display: flex;

            #iframeA {
                /*position: absolute;*/
                /*transform: scale(1, 1) translate(-50%, -50%);*/
                width: 100%;
                height: 580px;
                transform: scale(1, 1) translate(-50%, -45%);
                margin: auto;
            }

        }
    }

    .tab-content {
        width: 96%;
        margin: 10px auto;

        .rech {
            width: 100%;
            display: block;

            .listB {
                width: 100%;
                margin-top: 0px;
                background: #FFF;
                border: 1px solid rgba(230, 230, 230, 1);
                box-sizing: border-box;
                padding: 10px 40px;
                margin-bottom: 10px;

                span {
                    font-weight: bold;
                    padding-right: 10px;
                }

            }

            .listC {
                width: 100%;
                margin-top: 0px;
                background: #E8F6ED;
                border: 1px solid rgba(230, 230, 230, 1);
                box-sizing: border-box;
                padding: 10px 0px 0px 20px;
                display: flex;
                position: relative;

                .listC-z01 {
                    height: 24px;
                    line-height: 24px;
                    border-left: 3px solid #F77134;
                    padding-left: 10px;
                    box-sizing: border-box;
                    text-align: left;
                    font-weight: bold;
                    position: absolute;
                }

                .listC-z02 {
                    width: 100%;
                    height: 80px;
                    padding-left: 100px;
                    box-sizing: border-box;
                    text-align: left;
                    display: flex;

                    .tab-listC {
                        width: 70%;
                        height: 44px;
                        display: block;

                        .ar1 {
                            width: 100%;
                            display: flex;

                            li {
                                width: 130px;
                                float: left;
                                padding: 10px 20px;
                                text-align: center;
                                margin-right: 10px;
                                font-weight: bold;
                                background: #FFFFFF;
                                color: #303133;
                                border: 1px solid #CCCCCC;
                                cursor: pointer;
                                border-radius: 6px;
                            }

                            .active {
                                background-color: #FFF3E1;
                                border: 1px solid #FF4D00;
                                color: #303133;
                            }
                        }

                        .ar2 {
                            width: 100%;
                            height: 40px;
                            line-height: 40px;
                            color: #666;
                        }

                    }

                    .tab-listCt2 {
                        width: 96%;
                        height: 130px;
                        margin: 0px auto;
                        background-color: #42b983;

                    }
                }

            }
            .PayBtn {
                width: 100%;
                padding-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .Btn {

                }
            }
        }
    }

    .case-conent {
        margin: 20px auto;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        opacity: 0.9;
        /* 信息列表样式 */
        .msg-box > li {
            list-style: none;
            border-bottom: 1px solid #c5c5c5;
            padding: 20px 10px;
        }

        .hd {
            height: 40px;
            line-height: 40px;
            font-weight: bold;
            background: #37AEFF;
            text-align: center;
            color: #fff;
            opacity: 1;
        }


        .listA {
            width: 100%;
            margin-top: 0px;
            display: block;
            height: 350px;
            box-sizing: border-box;

            .el-radio-group {
                width: 100%;
                /* display: inline-block; */
            }

            .recList {
                width: 23.75%;
                opacity: 1;
                list-style-type: none;
                margin-right: 20px;
                font-weight: 800;
                box-sizing: border-box;
                /*text-align: center;*/
                cursor: pointer;
                float: left;

                .lst {
                    width: 100%;
                    height: 120px;
                    background: #FFF3E1;
                    border: 1px solid rgba(230, 230, 230, 1);
                    border-radius: 10px;
                    display: block;

                    .d1 {
                        width: 100%;
                        height: 90px;
                        box-sizing: border-box;
                        padding-top: 10px;
                        text-align: center;

                        img {
                            height: 80px;
                            margin: auto;
                        }
                    }

                    .d2 {
                        width: 100%;
                        height: 36px;
                        text-align: center;
                        color: #FF4D00;
                    }
                }

                .lb {
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                }


            }

            .recList:nth-child(4n+4) {
                border-right: 0;
                margin-right: 0;
                float: left;
                margin-bottom: 20px;
            }

            .recList:nth-child(n+5) {
                border-bottom: 0
            }


        }

        /* 点击对应的标题添加对应的背景颜色 */

        .PayB {
            width: 90%;

            h2 {
                text-align: center;
                height: 50px;
                line-height: 50px;
                font-weight: bold;
                padding-bottom: 30px;
                position: relative;
                padding-left: 50px;

                i {
                    vertical-align: middle;
                    position: absolute;
                    font-size: 46px;
                    color: #42b983;
                    margin-left: -50px;
                }
            }

            p {
                width: 80%;
                margin: auto;
                padding: 6px;

                span {
                    color: #FA6E86;
                }
            }
        }


        .EdTip {
            width: 98%;
            height: 24px;
            line-height: 24px;
            box-sizing: border-box;
            padding: 0 10px;
            background: #F6F4CE;
            border: 1px solid #FFDBA5;
            opacity: 1;
            color: #EF0000;
            border-radius: 4px;
            margin: 8px auto auto auto;
        }

        .sreachDiv {
            width: 99%;
            background: #ffffff;
            margin: 0px auto auto auto;
            display: flex;
            height: 70px;
            padding: 0px 0px;
            box-sizing: border-box;
            /*border-bottom: 1px dashed #ff6820;*/

            ::v-deep .el-input__inner {
                border: 1px solid rgba(230, 230, 230, 1) !important;
                color: #666;
                text-align: left !important;
                height: 40px;
                line-height: 40px;
            }

            .studentSleft {
                width: 99%;
                margin: auto;
                display: flex;

                .tSle {
                    margin-right: 10px;
                    //::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
                    //    width: 150px;
                    //}
                }

            }

            .iconBtnRg {
                width: 117px;
                margin-right: 0;
                float: right;
            }

        }

        .Slist {
            width: 100%;
            margin-top: 0px;
            //height: 1200px;
            box-sizing: border-box;
            padding: 0 14px;
            margin-bottom: 10px;
            display: block;


            .Sbooks {
                width: 18.7%;
                height: 280px;
                background: #000;
                border: 1px solid #DDDDDD;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                list-style-type: none;
                float: left;
                margin-right: 20px;
                background: rgba(255, 255, 255, 1);
                box-sizing: border-box;
                /*text-align: center;*/
                cursor: pointer;
                border: 1px solid rgba(230, 230, 230, 1);
                opacity: 0.9;
                float: left;

                .item-book-bg1 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg1.png") no-repeat;

                    img {
                        width: 68px;
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 34px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg2 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg2.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg3 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg3.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg23 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg1.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg24 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg2.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg25 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg3.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg26 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg8.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg27 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg9.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg28 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg7.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg29 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg6.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg30 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg5.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg31 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg4.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg32 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg10.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg33 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg10.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg35 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg44.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg4 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg4.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg5 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg5.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg6 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg6.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg7 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg7.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg8 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg8.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg9 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg9.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg10 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg10.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .item-book-bg11 {
                    width: 100%;
                    height: 180px;
                    border-radius: 10px 10px 0 0;
                    background: url("../../assets/images/item-book-bg11.png") no-repeat;

                    img {
                        /*width: 358px;*/
                        height: 104px;
                        margin-top: 20px;
                        margin-left: 50px;
                        float: left;
                        border: 0;
                    }
                }

                .Eda {
                    width: 96%;
                    padding: 6px 10px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #E5E5E5;
                    display: flex;
                }

                .Edu {
                    width: 96%;
                    margin: 0px auto;
                    padding: 6px 4px;
                    box-sizing: border-box;
                    display: flex;

                    .Edu-A {
                        width: 198px;
                        padding-left: 26px;
                        line-height: 29px;
                        box-sizing: border-box;
                        vertical-align: center;
                        display: flex;
                        background: url("../../assets/images/icon-press.png") no-repeat left 6px;

                        .Eca-A {
                            width: 50%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .Eca-B {
                            width: 50%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-decoration: line-through
                        }
                    }

                    .frequency {
                        width: 40px;
                        background: url("../../assets/images/case_icon03.png") no-repeat -3px 3px;
                        background-size: 21px 18px;
                        color: #FF6820;
                        text-align: right;
                        cursor: pointer;
                    }


                }

            }

            li:nth-child(5n+5) {
                border-right: 0;
                margin-right: 0;
                float: left;
                margin-bottom: 20px;
            }

            li:nth-child(n+5) {
                border-bottom: 0
            }

            li:hover {
                box-shadow: 0px 0px 12px 0px rgba(163, 163, 163, 0.35);
            }
        }

        .pag-box {
            width: 100%;
            padding-top: 10px;
            box-sizing: content-box;
            justify-content: center;
            display: flex;

            .total-box {
                float: left;
                line-height: 32px;
                margin-right: 10px;
                justify-content: flex-end;
                display: flex;

                .el-pagination {
                    white-space: nowrap;
                    padding: 2px 5px 2px 0px;
                    color: #303133;
                    font-weight: 700;

                    .el-pager li {
                        background: #ccc;
                        -webkit-box-sizing: border-box;
                    }

                    .el-pager li .active {
                        background-color: #FF6820;
                        color: #FFF;
                    }
                }


            }

        }
    }

</style>
