//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
// 查询筛选的学科列表：
export function findScreenSubjectObj(obj) {
    return request({
        url: '/search/parameters/findScreenSubject',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 精品学历案：
export function queryBoutiqueXlaObj(obj) {
    return request({
        url: '/study/homehots/queryBoutiqueXla',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 常用学历案：
export function queryCommUseXlaObj(obj) {
    return request({
        url: '/study/homehots/queryCommUseXla',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}

// 查询个人信息：
export function getStuByUserNameObj(obj) {
    return request({
        url: '/study/students/getStuByUserName',   // url = base url + request url
        method: 'get',

    })
}
// 查询学生届别：
export function findGradeObj(obj) {
    return request({
        url: '/search/parameters/findGrade',   // url = base url + request url
        method: 'get',

    })
}