//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

// 支付充值接口：
export function createOrderInfoObj(obj) {
    return request({
        url: '/web/orderInfo/createOrderInfo',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询学生学历案详情 ：
export function getOrderObj(obj) {
    return request({
        url: `/payment/pays/getOrder/${obj.orderNo}`,    // url = base url + request url
        method: 'get',
    })
}
